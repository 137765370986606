import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
    document.title = "Contact"
    return(
        <>
        <div id="contact-box">
            <h1>Contact</h1>
        </div>
        
        <ContactUs/>
        
        </>
    )
}

const ContactUs = () => {
  const form = useRef();
  const [warning, setWarning] = useState(false)
  const [sent, setSent] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const sendEmail = (e) => {
    e.preventDefault();
    if(name && email && message){
      setWarning(false)
      setSent(true)
      setName("")
      setEmail("")
      setMessage("")
      emailjs.sendForm('service_9w8pyhh', 'template_ar2fg0q', form.current, '1D6Vly_26qMid2nZ4')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });   
    }else{
      setWarning(true)
      setSent(false)
    }
  };

  

  return (
    <form id="contact-form"ref={form}>
      <label>Name*:</label>
      <input type="text" name="user_name" value={name} onChange={(e)=> setName(e.target.value)} />
      <br/>
      <label>Email*:</label>
      <input value={email} onChange={(e)=> setEmail(e.target.value)} type="email" name="user_email" />
      <br/>
      <label>Message*:</label>
      <textarea value={message} onChange={(e)=> setMessage(e.target.value)} name="message" />
      <br/>
      {warning && <p style={{color: "red"}}>Please fill in required field</p>}
      {sent && <p style={{color: "#33cc00"}}>Message sent</p>}

      <button onClick={sendEmail}type="submit">Send</button>
    </form>
  );
};
export default Contact